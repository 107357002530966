<template>
  <div class="blocks">
    <div v-for="block in blocks" class="blocks_block">
      <div class="container">
        <Block :block="block" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import './blocks.css'
import Block from '~/components/block/block.vue'
import type { TBlocks } from './blocks.type'

withDefaults(defineProps<TBlocks>(), {
  blocks: () => [],
})
</script>