const contextMap: Record<string, any> = {
  Hero: defineAsyncComponent(
    () => import('~/components/hero/hero.animation.vue'),
  ),
  // Current bug on DatoCMS normally : GridAnimationEnter
  GridAnimationEnter: defineAsyncComponent(
    () => import('~/components/grid/context/grid-animation-enter.vue'),
  ),
}
export default contextMap
