const blocksMap: Record<string, any> = {
  HeroSectionRecord: defineAsyncComponent(
    () => import('~/components/hero/hero.vue'),
  ),
  BodyModuleRecord: defineAsyncComponent(
    () => import('~/components/ui/body/body.vue'),
  ),
  GridSectionRecord: defineAsyncComponent(
    () => import('~/components/grid/grid.vue'),
  ),
  ImageModuleRecord: defineAsyncComponent(
    () => import('~/components/media/media.vue'),
  ),
  MediaModuleRecord: defineAsyncComponent(
    () => import('~/components/media/media.vue'),
  ),
  AccordionModuleRecord: defineAsyncComponent(
    () => import('~/components/accordion/accordion.vue'),
  ),
  CardModuleRecord: defineAsyncComponent(
    () => import('~/components/ui/card/card.vue'),
  ),
  SpacerModuleRecord: defineAsyncComponent(
    () => import('~/components/ui/spacer/spacer.vue'),
  ),
  SliderModuleRecord: defineAsyncComponent(
    () => import('~/components/slider/slider.vue'),
  ),
  QuoteModuleRecord: defineAsyncComponent(
    () => import('~/components/quote/quote.vue'),
  ),
  TextModuleRecord: defineAsyncComponent(
    () => import('~/components/ui/text/text.vue'),
  ),
  FormModuleRecord: defineAsyncComponent(
    () => import('~/components/ui/form/form.vue'),
  ),
}

export default blocksMap
